import L, { DivIcon, DivIconOptions } from "leaflet"
import { CSSProperties, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { Marker, Popup } from "react-leaflet"
import { saveEvent } from "../../../analytics/Tracker"
import { Item } from "../../../models/Item"
import { SpawnsEntity } from "../../../models/Monster"
import { KEBARAS, MADRIGAL } from "../../../Store"
import { centerPoint } from "../../Map/Map"
import MarkerImage from "../MarkerImage"
import { ItemInfo } from "./ItemInfo/ItemInfo"


export function CreateItemMarkerProps(item: Item, spawn?: SpawnsEntity, world?: number): ItemMarkerProps {
    return {
        id: item.id,
        name: item.name.en,
        description: item.description.en,
        rarity: item.rarity,
        image: item.image,
        spawn: spawn,
        world: world
    }
}

function styleRoot(): CSSProperties {
    return {
        display: 'block',
        width: 'max-content',
        maxWidth: '100px',
        textAlign: 'center',
        zIndex: 5000,
    }
}

export interface ItemMarkerProps {
    id: number,
    name: string,
    description: string,
    rarity: string,
    image: string,
    spawn: SpawnsEntity | undefined,
    world: number | undefined
}



let timeoutId;
export function ItemMarker(props: ItemMarkerProps) {
    const [isHovering, setHovering] = useState<boolean>(false)

    const handleMouseEnter = () => {

        timeoutId = setTimeout(() => {
            setHovering(true)
        }, 300)
    }

    const handleMouseLeave = () => {
        clearTimeout(timeoutId)
        setHovering(false)

    }

    function displayMarker() {
        let divIcon = <div style={styleRoot()}>
            <MarkerImage path={props.image} height={'25px'} name={props.name}></MarkerImage>
        </div>
        let icon: DivIcon = L.divIcon({
            className: 'item-marker',
            iconAnchor: [25, 35],
            html: ReactDOMServer.renderToString(divIcon)
        } as DivIconOptions)


        let position;
        if (props.spawn?.world === MADRIGAL.id) {
            position = centerPoint(props.spawn, MADRIGAL)
        } else if (props.spawn?.world === KEBARAS.id) {
            position = centerPoint(props.spawn, KEBARAS)
        } else {
            position = [props.spawn?.x, props.spawn?.y]
        }

        return <Marker
            position={position}
            icon={icon}
            // draggable
            eventHandlers={{
                'click': () => {
                    saveEvent('item_spawn_clicked', {
                        itemId: props.id,
                        itemName: props.name
                    })
                },
                'mouseover': () => {
                    handleMouseEnter()
                },
                'mouseout': () => {
                    handleMouseLeave()
                },
                'dragend': (e) => {
                    console.log(e.target.getLatLng().toString())
                }
            }}
        >

            <Popup>
                <ItemInfo {...props}></ItemInfo>
            </Popup>

        </Marker>
    }


    return (
        <div>
            {displayMarker()}
        </div>
    )
}