import L, { LatLngBoundsExpression, LatLngTuple, Map as LeafletMap } from 'leaflet';
import { CSSProperties, useContext, useEffect, useState } from 'react';
import './Map.css'
import { MapContainer, useMapEvents } from 'react-leaflet'
import { Monster } from '../../models/Monster'
import { CreateMonsterMarkerProps, MonsterMarker } from '../MapMarker/Monster/MonsterMarker';
import { BBox } from "geojson";
import { clusterByDistance, groupByName, MonsterPoint } from '../MapMarker/ClusterMarker/Clusterer';
import { ClusterMarker } from '../MapMarker/ClusterMarker/ClusterMarker';
import { Item } from '../../models/Item';
import { CreateItemMarkerProps, ItemMarker } from '../MapMarker/ItemMarker/ItemMarker';
import { Data, DEKANE, IBLIS, KEBARAS, MADRIGAL, MARS, Store } from '../../Store';
import { ZoomController } from './ZoomController';
import { Place } from '../../models/World';
import { DungeonMarker } from '../Dungeon/DungeonMarker';
import 'leaflet-imageoverlay-rotated';
import { dekaneSpawns } from '../Dungeon/DekaneSpawns';
import { marsSpawns } from '../Dungeon/MarsSpawns';
import { ibilisSpawns } from '../Dungeon/IbilisSpawns';
import { DungonOverlays, setUpDungeons } from './DungeonMaps';


function styleMap(): CSSProperties {
  return {
    height: '100vh'
  }
}

const MAP_ID = 'madrigal-map'

export function makeRectBounds(spawn, world): LatLngBoundsExpression {
  let topLeft: LatLngTuple = [spawn.top - world.height, spawn.left];
  let bottomRight: LatLngTuple = [spawn.bottom - world.height, spawn.right];

  return [topLeft, bottomRight]
}

export function centerPoint(spawn, world): LatLngTuple {
  let x = (spawn.left + spawn.right) / 2
  let y = ((spawn.top - world.height) + (spawn.bottom - world.height)) / 2

  return [y, x]
}

export interface MapProps {
  monsters: Monster[],
  itemSpawns: Item[]
}
export function Map(props: MapProps) {
  //@ts-ignore
  const [map, setMap] = useState<LeafletMap>()
  const [bounds, setBounds] = useState<BBox>();
  const [zoom, setZoom] = useState(-1);
  const [dungeonOverlays] = useState<DungonOverlays>(setUpDungeons())


  const store: any = useContext(Store)
  const { dispatch } = store
  const state: Data = store.state
  const allMarkers: Array<JSX.Element> = []

  function setBoundsAndZoom() {
    setBounds([
      map!.getBounds().getSouthWest().lng,
      map!.getBounds().getSouthWest().lat,
      map!.getBounds().getNorthEast().lng,
      map!.getBounds().getNorthEast().lat,
    ])

    setZoom(map!.getZoom())
  }


  function EventHandler() {
    const map = useMapEvents({
      click: (e) => {
        console.log(e.latlng.toString())
        // console.log(map.getZoom())
      },
      load: () => {
        setBoundsAndZoom()
      },
      moveend: () => {
        setBoundsAndZoom()

      },
      zoomstart: (e) => {
        // console.log(e.target.options.zoom)

      }

    })

    return null
  }



  function displayMonsters() {
    let toDisplay: Array<JSX.Element> = []

    if (!map) {
      return toDisplay
    }

    if (state.activeDungeon === 0) {
      clusterByDistance(props.monsters, map!)?.forEach((cluster: MonsterPoint[], key: string) => {
        let marker;

        if (cluster.length === 1 && zoom > -1) {
          marker = <MonsterMarker key={key} {...cluster[0].properties.monster} />
        } else {
          groupByName(cluster).forEach((group: MonsterPoint[], name: string) => {
            marker = <ClusterMarker key={key + name} monsterGroup={group} cluster={cluster} map={map} groupName={name} />
          })
        }
        toDisplay.push(marker)
        allMarkers.push(marker)

      })
    }
    return toDisplay
  }

  function displayDekaneMonsters() {
    let toDisplay: Array<JSX.Element> = []

    if (state.activeDungeon === DEKANE.id) {
      props.monsters.filter(m => m.area === 'dungeon').forEach(m => {
        m.spawns?.forEach(spawn => {
          if (spawn.world === DEKANE.id) {
            toDisplay.push(<MonsterMarker {...CreateMonsterMarkerProps(m)} world={DEKANE.id} x={spawn.x!} y={spawn.y!}></MonsterMarker>)
          }
        })

      })


      const CILLIN = 5403
      let cillin = props.itemSpawns.find(i => i.id === CILLIN)

      if (cillin) {
        dekaneSpawns(CILLIN).forEach((s, i) => {
          toDisplay.push(<ItemMarker key={`${i}-${CILLIN}`} {...CreateItemMarkerProps(cillin!, { ...cillin!.spawns![0], ...{ x: s[0], y: s[1] } }, DEKANE.id)} ></ItemMarker>)
        })
      }


    }

    if (map && map?.getZoom() < 0)
      return []

    return toDisplay
  }

  function displayMarsMonsters() {
    let toDisplay: Array<JSX.Element> = []

    if (state.activeDungeon === MARS.id) {
      props.monsters.filter(m => m.area === 'dungeon').forEach(m => {
        m.spawns?.forEach(spawn => {
          if (spawn.world === MARS.id) {
            toDisplay.push(<MonsterMarker {...CreateMonsterMarkerProps(m)} world={MARS.id} x={spawn.x!} y={spawn.y!}></MonsterMarker>)
          }
        })

      })


      const PIECE_OF_KEY = 5037
      let pieceOfKey = props.itemSpawns.find(i => i.id === PIECE_OF_KEY)

      if (pieceOfKey) {
        marsSpawns(PIECE_OF_KEY).forEach((s, i) => {
          toDisplay.push(<ItemMarker key={`${i}-${PIECE_OF_KEY}`} {...CreateItemMarkerProps(pieceOfKey!, { ...pieceOfKey!.spawns![0], ...{ x: s[0], y: s[1] } }, MARS.id)} ></ItemMarker>)
        })
      }
    }

    if (map && map?.getZoom() < 0)
      return []

    return toDisplay
  }


  function displayIblisMonsters() {
    let toDisplay: Array<JSX.Element> = []

    if (state.activeDungeon === IBLIS.id) {
      props.monsters.filter(m => m.area === 'dungeon').forEach(m => {
        m.spawns?.forEach(spawn => {
          if (spawn.world === IBLIS.id) {
            toDisplay.push(<MonsterMarker {...CreateMonsterMarkerProps(m)} world={DEKANE.id} x={spawn.x!} y={spawn.y!}></MonsterMarker>)
          }
        })
      })



      const RUBBED_COPY = 1942
      let rubbedCopy = props.itemSpawns.find(i => i.id === RUBBED_COPY)

      if (rubbedCopy) {
        ibilisSpawns(RUBBED_COPY).forEach((s, i) => {
          toDisplay.push(<ItemMarker key={`${i}-${RUBBED_COPY}`} {...CreateItemMarkerProps(rubbedCopy!, { ...rubbedCopy!.spawns![0], ...{ x: s[0], y: s[1] } }, IBLIS.id)} ></ItemMarker>)
        })
      }


    }

    if (map && map?.getZoom() < 0)
      return []

    return toDisplay
  }

  function displayItemsSpawns() {
    let toDisplay: Array<JSX.Element> = []

    props.itemSpawns.forEach((item, i) => {
      item.spawns?.forEach((spawn, j) => {
        if (state.activeDungeon === 0 && (spawn.world === MADRIGAL.id || spawn.world === KEBARAS.id)) {
          let marker = <ItemMarker key={`${i}-${j}-${item.id}`} {...CreateItemMarkerProps(item, spawn)}></ItemMarker>
          toDisplay.push(marker)
          allMarkers.push(marker)
        }

      })
    })

    return toDisplay

  }

  function displayDungeons() {
    let toDisplay: Array<JSX.Element> = []

    let dungeons = state.madrigal?.places?.filter((place: Place) => place.type === 'dungeon')
    dungeons?.forEach(dungeon => {
      toDisplay.push(<DungeonMarker dungeon={dungeon} ></DungeonMarker>)
    })

    return toDisplay
  }

  useEffect(() => {
    // console.log(state.matches.length)
  }, [state.matches])

  //https://flyff-api.sniegu.fr/image/world/wdkebaras{x}-{y}-0.png
  useEffect(() => {
    let madrigal: L.Layer = new L.TileLayer("https://storage.googleapis.com/pieces-of-roika/wdmadrigal/{x}-{y}-0.png", {
      tileSize: MADRIGAL.tileSize,
      zoomOffset: -1,
      minZoom: -4,
      maxZoom: 1,
      minNativeZoom: 0,
      maxNativeZoom: 0,
      bounds: L.latLngBounds(L.latLng(0, MADRIGAL.width), L.latLng(-MADRIGAL.height, 0)),
      // bounds={[[0, 0], [-kebaras.height, kebaras.width]]}
      keepBuffer: 1000,
      noWrap: true,
      attribution: '&copy; 2021 Gala Lab Corp.'
    })



    let kebaras: L.Layer = new L.TileLayer('https://storage.googleapis.com/pieces-of-roika/wdkebaras/{x}-{y}-0.png',
      {
        tileSize: KEBARAS.tileSize,
        bounds: [[0, 0], [-KEBARAS.height, KEBARAS.width]],
        minZoom: -4,
        maxZoom: 1,
        minNativeZoom: 0,
        maxNativeZoom: 0,

      })



    map?.addLayer(madrigal)
    map?.addLayer(kebaras)
  }, [map]);


  useEffect(() => {
    console.log(state.activeDungeon)

    if (state.activeDungeon === MARS.id) {
      map?.removeLayer(dungeonOverlays.iblis)
      map?.removeLayer(dungeonOverlays.dekane)
      map?.addLayer(dungeonOverlays.mars)
    } else if (state.activeDungeon === IBLIS.id) {
      map?.removeLayer(dungeonOverlays.mars)
      map?.removeLayer(dungeonOverlays.dekane)
      map?.addLayer(dungeonOverlays.iblis)
    } else if (state.activeDungeon === DEKANE.id) {
      map?.removeLayer(dungeonOverlays.mars)
      map?.removeLayer(dungeonOverlays.iblis)
      map?.addLayer(dungeonOverlays.dekane)
    } else {
      map?.removeLayer(dungeonOverlays.mars)
      map?.removeLayer(dungeonOverlays.iblis)
      map?.removeLayer(dungeonOverlays.dekane)
    }


  }, [state.activeDungeon]);

  return (
    <div id={MAP_ID} style={styleMap()}>
      <MapContainer style={styleMap()}
        crs={L.CRS.Simple}
        center={[-1800, 7007]}
        zoom={zoom}
        minZoom={-3}
        maxZoom={1}
        maxBounds={L.latLngBounds(L.latLng(0, MADRIGAL.width), L.latLng(-MADRIGAL.height, 0))}
        // attributionControl
        whenCreated={map => setMap(map)}
        zoomControl={false}
      >

        {map && <ZoomController map={map}></ZoomController>}
        <EventHandler></EventHandler>

        {displayMonsters()}
        {displayItemsSpawns()}
        {props.monsters.length > 0 && displayDungeons()}
        {(map && map.getZoom() < 2) ? displayDekaneMonsters() : null}
        {(map && map.getZoom() < 2) ? displayMarsMonsters() : null}
        {(map && map.getZoom() < 2) ? displayIblisMonsters() : null}
        {/* 0: {world: 2927, left: 1245, top: 608, right: 1247, bottom: 610} */}

        {/* <Marker position={[0,MADRIGAL.width]}/> */}


        {/* <Marker position={[94.12144 - MADRIGAL.height, 8017]}/> 
        <Marker position={[-3106, 8017]}/> 
        <Marker position={[0,MADRIGAL.width]}/>  */}



      </MapContainer>
    </div>
  )
}