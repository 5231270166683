import { CSSProperties, useContext } from "react";
import { saveEvent } from "../../../../../analytics/Tracker";
import { ACTION_UPDATE_CATEGORY_FILTER, ARMOR, Data, JEWLERY, MISC, Store, WEAPON } from "../../../../../Store";
import { ARMOUR_ICON, JEWELRY_ICON, MISC_ICON, WEAPON_ICON } from "./CategoryFilterIcons";



export function LootListTypeFilter(props) {

    const store: any = useContext(Store)
    const { dispatch } = store
    const state:Data = store.state

    // const [lastClick, setLastClick] = useState('')
    

    function styleRoot(): CSSProperties {
        return {
            display: 'flex',
            justifyContent: 'space-around',
            padding: '5px 0 5px 0'
        }
    }

    function styleImage(category: string): CSSProperties {
        let style = {
            cursor: 'pointer',
            width: '25px',
            height: '25px',
            filter: 'invert(77%) sepia(1%) saturate(5%) hue-rotate(345deg) brightness(50%) contrast(50%)'
        }

        
        if (state.categoryFilter === category) {
            style.filter = ''
        }

        return style
    }

    function handleFilterChange(category: string) {
        saveEvent('category_filter', {
            category: category
        })
        
        dispatch({type: ACTION_UPDATE_CATEGORY_FILTER, value: category})
    }

    return (
        <div style={styleRoot()}>
            <img style={styleImage(WEAPON)} onClick={() => handleFilterChange(WEAPON)} src={WEAPON_ICON} title='Filter weapons'/>
            <img style={styleImage(ARMOR)} onClick={() => handleFilterChange(ARMOR)} src={ARMOUR_ICON} title='Filter armor'/>
            <img style={styleImage(JEWLERY)} onClick={() => handleFilterChange(JEWLERY)} src={JEWELRY_ICON} title='Filter jewelery'/>
            <img style={styleImage(MISC)} onClick={() => handleFilterChange(MISC)} src={MISC_ICON} title='Filter misc'/>
        </div>
    )
}