import { CSSProperties } from "react";
import { MonsterMarkerProps } from "../MonsterMarker";
import { ElementBubble } from "./ElementBubble";
import { LevelBubble } from "./LevelBubble";
import { LootList } from "./Loot/LootList";
import { Portrait } from "./Portrait";

function styleRoot(): CSSProperties {
    return {
        width: '245px',
        textAlign: 'center',
        alignContent: 'center',
    }
}

function styleName(): CSSProperties {
    return {
        fontWeight: 'bolder',
        fontSize: '15px'
    }
}


function styleBar(type): CSSProperties {
    return {
        background: type === 'mp' ? '#116bdc' : '#d8061a',
        width: '100%',
        // height: '10px',
        textAlign: 'center',
        borderRadius: '10px',
        borderColor: 'black',
        color: "white",
        fontWeight: 'bolder',
        marginTop: '-10px'
    }
}


function styleBubbles(): CSSProperties {
    return {
        position: 'relative',
    }
}

function styleLevel(): CSSProperties {
    return {
        position: 'absolute',
        top: '1%',
        left: '1%'
    }
}


function styleElement(): CSSProperties {
    return {
        position: 'absolute',
        bottom: '1%',
        right: '1%'
    }
}

function styleInfo(): CSSProperties {
    return {
        marginTop: '-10px',

    }
}



export function MonsterInfo(props: MonsterMarkerProps) {

    return (
        <div style={styleRoot()}>

            <div style={{ margin: '13px 19px' }}>
                <div style={styleBubbles()}>
                    <Portrait image={props.image} element={props.element}></Portrait>

                    <div style={styleLevel()}>
                        <LevelBubble level={props.level} />
                    </div>

                    {props.element !== 'none' &&
                        <div style={styleElement()}>
                            <ElementBubble element={props.element} />
                        </div>}
                </div>

                <div style={styleInfo()}>
                    <p style={styleName()}>{props.name}</p>

                    <div style={styleBar('hp')}>
                        <p>{props.hp.toLocaleString()}</p>
                    </div>
                    <div style={styleBar('mp')}>
                        <p>{props.mp.toLocaleString()}</p>
                    </div>
                </div>
            </div>


            <LootList loot={props.loot} />

        </div>
    )

}