import L, { DivIcon, DivIconOptions, map, Map } from "leaflet";
import { CSSProperties } from "react";
import ReactDOMServer from "react-dom/server";
import { Marker } from "react-leaflet";
import { saveEvent } from "../../../analytics/Tracker";
import { MonsterPoint } from "./Clusterer";
import MarkerImage from "../MarkerImage";
import { ClusterName } from "./ClusterName";

function styleRoot(): CSSProperties {
    return {
        display: 'block',
        width: 'max-content',
        minWidth: '50px',
        textAlign: 'center',
        border: '13px sold red'
    }
}


export interface ClusterMarker {
    monsterGroup: MonsterPoint[],
    cluster: MonsterPoint[],
    map: Map,
    groupName: string,
}

function calculateClusterCenter(cluster: Array<MonsterPoint>): L.LatLngTuple {

    let giant = cluster.filter(mp => mp.properties.monster.rank.toLowerCase() === 'giant')[0]

    if (giant &&
        !giant.properties.monster.name.toLowerCase().includes('syliaca') &&
        !giant.properties.monster.name.toLowerCase().includes('dump') &&
        !giant.properties.monster.name.toLowerCase().includes('gorruda') &&
        !giant.properties.monster.name.toLowerCase().includes('wheelem') &&
        !giant.properties.monster.name.toLowerCase().includes('neparuba')
    ) {
        let point = [giant.geometry.coordinates[1], giant.geometry.coordinates[0]]

        if (giant.properties.monster.name.toLowerCase().includes('burudeng')) {
            point[0] = point[0] + 200
            point[1] = point[1] + 30
        }

        if (giant.properties.monster.name.toLowerCase().includes('mushpang')) {
            // point[0] = point[0] + 200
            point[1] = point[1] + 75
        }

        if (giant.properties.monster.name.toLowerCase().includes('turtle')) {
            point[0] = point[0] - 30
            point[1] = point[1] + 90
        }

        if (giant.properties.monster.name.toLowerCase().includes('trangfoma')) {
            point[0] = point[0] + 50
            point[1] = point[1] + 75
        }

        if (giant.properties.monster.name.toLowerCase().includes('chef muffrin')) {
            point[0] = point[0] - 33
            point[1] = point[1] + 30
        }

        //@ts-ignore
        return point
    }

    if (cluster[0].properties.monster.name.toLowerCase().includes('organigor')) {
        let point = [cluster[0].geometry.coordinates[1], cluster[0].geometry.coordinates[0]]

        point[0] = point[0]
        point[1] = point[1] - 15
        //@ts-ignore
        return point
    }

    if (cluster[0].properties.monster.name.toLowerCase().includes('gangard')) {
        let point = [cluster[0].geometry.coordinates[1], cluster[0].geometry.coordinates[0]]

        point[0] = point[0] + 20
        point[1] = point[1] + 20
        //@ts-ignore
        return point
    }

    if (cluster[0].properties.monster.name.toLowerCase().includes('hadeseor')) {
        let point = [cluster[0].geometry.coordinates[1], cluster[0].geometry.coordinates[0]]

        point[0] = point[0] + 25
        point[1] = point[1] - 5
        //@ts-ignore
        return point
    }



    let bound: L.LatLngBounds = new L.LatLngBounds(cluster.map(point => [point.geometry.coordinates[0], point.geometry.coordinates[1]]))
    let center: L.LatLngTuple = [(bound.getWest() + bound.getEast()) / 2, ((bound.getNorth() + bound.getSouth()) / 2)]

    return center
}

function imageSize(zoom: number, cluster: Array<MonsterPoint>): string {
    if (cluster.length === 1 && (cluster[0].properties.monster.id === 7898 || cluster[0].properties.monster.id === 1439)) {
        return '30px'
    }

    if (zoom < -2) {
        return '40px'
    } else if (zoom === -2) {
        return '50px'
    } else if (zoom === -1) {
        return '50px'
    }

    return '50px'
}

function createClusterName(cluster: Array<MonsterPoint>): string {
    let toReturn = ''
    let giant = cluster.filter(mp => mp.properties.monster.rank.toLowerCase() === 'giant')[0]

    if (giant) {
        let name = giant.properties.monster.name
        let parts = name.split(' ')

        if (giant.properties.monster.name === 'Lord Bang')
            return giant.properties.monster.name

        if (parts.length > 1) {
            toReturn = name.replace(parts[0], '')
        }

    } else {
        toReturn = cluster[0].properties.monster.name

    }

    toReturn = toReturn.replace('Small', '')
    toReturn = toReturn.replace('Phantom', '')
    toReturn = toReturn.replace('Rough', '')
    toReturn = toReturn.replace('Mage', '')
    toReturn = toReturn.replace('Captain', '')
    toReturn = toReturn.replace('1st Class', '')
    toReturn = toReturn.replace('2nd Class', '')
    toReturn = toReturn.replace('Grownup', '')
    toReturn = toReturn.replace('Young', '')
    return toReturn

}

export function ClusterMarker(props: ClusterMarker) {

    let bound: L.LatLngBounds = new L.LatLngBounds(props.cluster.map(point => [point.geometry.coordinates[0], point.geometry.coordinates[1]]))
    let coords: L.LatLngTuple = [((bound.getNorth() + bound.getSouth()) / 2), (bound.getWest() + bound.getEast()) / 2]

    function displayMarker() {
        let avg = props.monsterGroup.map(m => avg += Number(m.properties.monster.level))

        let img;

        if (props.monsterGroup.length > 1) {
            let m = props.monsterGroup.find(mp => mp.properties.monster.rank.toLowerCase() === 'small')
            if (!m) {
                m = props.monsterGroup.find(mp => mp.properties.monster.rank.toLowerCase() === 'normal')
            }
            img = m?.properties.monster.image
        }

        if (!img)
            img = props.monsterGroup[0].properties.monster.image


        let divIcon = <div style={styleRoot()}>
            <MarkerImage path={img} height={imageSize(props.map.getZoom(), props.cluster)} name={props.groupName}></MarkerImage>
            {props.map.getZoom() > -3 && <ClusterName name={createClusterName(props.cluster)} />}

        </div>

        let icon: DivIcon = L.divIcon({
            className: 'monster-cluster',
            iconAnchor: [28, 15],
            html: ReactDOMServer.renderToString(divIcon)
        } as DivIconOptions)


        let position = calculateClusterCenter(props.cluster)

        return <Marker
            position={[position[1], position[0]]}
            icon={icon}
            eventHandlers={{
                'click': () => {
                    saveEvent('cluster_clicked', {
                        zoomLevel: props.map.getZoom()
                    })

                    props.map.flyTo(coords, 1)

                }
            }}
        >

        </Marker>
    }

    return (
        <div onContextMenu={(e) => e.preventDefault()}>
            {displayMarker()}
        </div>
    )
}