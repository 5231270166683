import { CSSProperties } from "react"


export function MonsterLevel(props) {

    function styleRoot(): CSSProperties {
        return {
            width: '50px',
            background: '#7C76A0',
            textAlign: 'center',
            borderRadius: '5px',
            color: '#F0F3F5',
            opacity: "0.9",
            margin: '0 auto 0 auto',
            border: 'solid 1.5px #f0f3f5da'
        
        }
    }

    return (
        <div style={styleRoot()}>
          <b>Lv.  {props.level}</b>
        </div>
    )
}