import { CSSProperties } from "react"

export interface PortraitProps {
    image: string,
    element: string
}


function styleRoot(): CSSProperties {
    return {
        position: 'relative',
        background:'#b3b3b3',
        borderRadius: '50%',
        width: '150px',
        height: '150px',
        overflow: 'hidden',
        left: '50%',
        transform: 'translateX(-50%)',
    }
}


function styleImage(): CSSProperties {
    return {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateY(-50%) translateX(-50%)',
        height: 'auto',
        maxHeight: '125px',
        maxWidth: '125px'
    }
}


export function Portrait(props: PortraitProps) {
    return (
        <div style={styleRoot()}>
            <img style={styleImage()} src={props.image}/>
        </div>    
    )
}