import { CSSProperties } from "react";
import { ItemMarkerProps } from "../ItemMarker";
import { nocontext } from "../../../../App";
import { saveEvent } from "../../../../analytics/Tracker";



export function ItemInfo(props: ItemMarkerProps) {


    function rarityColor(rarity: string) {
        switch(rarity) {
            case 'common': return '#78d9ff'
            case 'uncommon': return '#804000'
            case 'rare': return '#00aa00'
            case 'veryrare': return '#00aa00'
            case 'unique': return '#d20000'
        }
    }


    function styleRoot(): CSSProperties {
        let style = {
            width: '200px',
            display: 'flex',
            alignItems: 'center',
        }

        if(props.spawn)
            style['margin'] = '13px 19px' 

        return style
    }

    function styleImage(): CSSProperties {

        let style = {
            width: '45px',
            height: '45px',
            maxWidth: '45px',
            maxHeight: '45px',
            marginLeft: '-10px',
            flex: 1,
        }

        if(!props.spawn) {
            style = {
                ...style, 
                width: '35px',
                height: '35px',
                marginLeft: '3px'
            }

        }

        return style
    }


    function styleTextContainer(): CSSProperties {
        return {
            flex: 5,
            marginLeft: '10px'
        }
    }

    function styleName(): CSSProperties {
        return {
            margin: 0,
            fontSize: '15px',
            fontWeight: 'bolder',
            color: rarityColor(props.rarity)
        }
    }

    function styleDisc(): CSSProperties {
        return {
            display: 'inline-block',
            margin: 0,

        }
    }

    function onItemImgClick() {
        saveEvent('item_img_click', {
            itemId: props.id,
            itemName: props.name
        })
    }

    function onItemInfoClick() {
        saveEvent('item_info_click', {
            itemId: props.id,
            itemName: props.name
        })
    }

    return (
        <div  onContextMenu={(e) => nocontext(e)} style={styleRoot()}>
            <img onClick={onItemImgClick} style={styleImage()} src={props.image} alt={`${props.name}`}></img>

            <div onClick={onItemInfoClick} style={styleTextContainer()}>
                <p style={styleName()}>{props.name}</p>
                {props.description && <p style={styleDisc()}>{props.description}</p>}
            </div>
        </div>
    )
}