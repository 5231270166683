import axios from "axios";
import { CSSProperties, useContext, useEffect, useState } from "react";
import { saveEvent } from "../../../../../analytics/Tracker";
import { Item } from "../../../../../models/Item";
import { DropsEntity } from "../../../../../models/Monster";
import { ACTION_TOGGLE_DISPLAY_LOOT, ARMOR, Data, JEWLERY, MISC, Store, WEAPON } from "../../../../../Store";
import { ItemInfo } from "../../../ItemMarker/ItemInfo/ItemInfo";
import { CreateItemMarkerProps } from "../../../ItemMarker/ItemMarker";
import { LootListTypeFilter } from "./CategoryFilter";
import { JobFilter } from "./JobFilter";
import './LootList.css';


function styleList(): CSSProperties {
    return {
        height: 'auto',
        minHeight: '100px',
        maxHeight: '200px',
        overflowY: 'scroll',
        overflowX: 'hidden',
        listStyleType: 'none',
        padding: 0,
        margin: '0 -2px 5px 5px',
        // boxShadow: '0 -5px 5px #888'
    }
}

function styleListItem(): CSSProperties {
    return {
        textAlign: 'center',
        padding: '1px 0 1px 0',
        borderTop: 'solid 1px #0000002e'
    }
}

function styleLootHeader(): CSSProperties {
    return {
        margin: '-3px 0 5px 0',

    }
}

function styleCategoryFiler(): CSSProperties {
    return {
        boxShadow: '0 5px 5px #04040452',

    }
}


export interface LootListProps {
    loot: Array<DropsEntity>
}
export function LootList(props: LootListProps) {
    const [isLoading, setIsLoading] = useState(false)
    const [loot, setLoot] = useState<Array<Item>>([])
    const store: any = useContext(Store)
    const { dispatch } = store
    const state: Data = store.state

    useEffect(() => {
        setIsLoading(true)
        axios.get(`/items?ids=${props.loot.map(i => i.item).toString()}`)
            .then(r => {
                setLoot(r.data.items.sort((a: Item, b: Item) => {
                    if (a.name.en > b.name.en)
                        return 1
                    if (a.name.en < b.name.en)
                        return -1
                    return 0
                }))
            }).catch(e => {
                console.log(e)
            }).finally(() => {
                setIsLoading(false)
            })
    }, [])


    function displayList() {
        //@ts-ignore
        let toDisplay: Array<JSX.Element> = loot.map(item => {

            if(state.categoryFilter === MISC && item.category !== ARMOR && item.category !== WEAPON && item.category !== JEWLERY) {
                return <li style={styleListItem()}>
                    <ItemInfo key={item.id} {...CreateItemMarkerProps(item)}></ItemInfo>
                </li>
            }

            if (
                (state.categoryFilter === item.category || !state.categoryFilter) &&
                (!state.jobFilters || !item.class || state.jobFilters.includes(item.class))
            ) {
                return <li style={styleListItem()}>
                    <ItemInfo key={item.id} {...CreateItemMarkerProps(item)}></ItemInfo>
                </li>
            }

        })

        return (
            <ul style={styleList()}>
                {toDisplay}
            </ul>
        )

    }

    function toggleLoot() {
        saveEvent('toogle_loot', {
            value: !state.lootDisplayed
        })


        dispatch({ type: ACTION_TOGGLE_DISPLAY_LOOT })
    }



    return (
        <div>
            <div className='loot-list-toggle' onClick={() => toggleLoot()}>
                {!state.lootDisplayed && <div className='arrow-down'></div>}
                <p style={styleLootHeader()} >
                    {`Drops ${props.loot.length} items`}
                </p>
                {!state.lootDisplayed && <div className='arrow-down'></div>}
            </div>

            {state.lootDisplayed && loot && loot.length > 0 && <div style={styleCategoryFiler()}>
                <LootListTypeFilter />
            </div>}
            {state.lootDisplayed && loot && loot.length > 0 && <div>
                {displayList()}
                {<JobFilter loot={loot} />}
            </div>}

        </div>
    )
}