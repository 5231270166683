import L from "leaflet"
import { DEKANE, IBLIS, MARS } from "../../Store"
import { rotatePoint } from "../MapMarker/ClusterMarker/Clusterer"


export interface DungonOverlays {
    mars: L.ImageOverlay,
    iblis: L.ImageOverlay,
    dekane: L.ImageOverlay,

}

export function setUpDungeons(): DungonOverlays {
    let dekanex = 4330
    let dekaney = -875
    let dekane: L.ImageOverlay = new L.ImageOverlay('/images/dekane2.png', [[dekaney, dekanex], [dekaney - DEKANE.height, dekanex + DEKANE.width]])


    let ibilisx = 8212
    let ibilisy = -2680
    let iblis: L.ImageOverlay = new L.ImageOverlay('/images/ibilis.png', [[ibilisy, ibilisx], [ibilisy - IBLIS.height, ibilisx + IBLIS.width]])



    let marsx = 7330
    let marsy = -900
    let rotateAngle = .5
    let p1 = rotatePoint(marsx, marsy, marsx, marsy, rotateAngle)
    let p2 = rotatePoint(marsx + MARS.width, marsy, marsx, marsy, rotateAngle)
    let p3 = rotatePoint(marsx, marsy - MARS.height, marsx, marsy, rotateAngle)
    let mars2: L.ImageOverlay = L.imageOverlay.rotated('/images/mars4.png', L.latLng(p1[1], p1[0]), L.latLng(p2[1], p2[0]), L.latLng(p3[1], p3[0]), { interactive: true })

    return {
        mars: mars2,
        iblis: iblis,
        dekane: dekane
    }

}