import { useContext, useEffect, useState } from "react";
import { saveEvent } from "../../analytics/Tracker";
import { ACTION_SEARCH, ACTION_SET_SEARCHBAR_TARGET, ACTION_SPAWN_BOUNDS_TOGGLE, Data, Store } from "../../Store";
import './SearchBar.css';
import { AIBAT, GORE } from "./SearchBarImages";
import './Toolbar.css';

let timeoutId;
export default function SearchBar(props) {
    const [searchValue, setSearchValue] = useState<string>('')
    const [searchFocused, setSearchFocus] = useState<boolean>(false)
    const store: any = useContext(Store)
    const { dispatch } = store
    const state: Data = store.state

    useEffect(() => {
        if (!searchValue) {
            dispatch({ type: ACTION_SEARCH, value: searchValue })
            return
        }


        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            saveEvent('searched_string', {
                searchValue: searchValue
            })

            dispatch({ type: ACTION_SEARCH, value: searchValue })
        }, 500)
        return () => { clearTimeout(timeoutId) }
    }, [searchValue])

    const handSearchChange = (value: string) => {
        setSearchValue(value)
    }

    function toggleSearchTarget() {

        let newValue;

        if (state.searchBarTarget === 'monsters')
            newValue = 'items'
        else
            newValue = 'monsters'


        saveEvent('search_toggled', {
            value: newValue
        })



        dispatch({ type: ACTION_SET_SEARCHBAR_TARGET, value: newValue })
        dispatch({ type: ACTION_SEARCH, value: searchValue })

    }

    function searchPlaceHolder() {
        if (searchFocused) {
            return ''
        }
        if (state.searchBarTarget === 'monsters')
            return 'Find something to kill...'
        else
            return 'Where loot?'
    }

    function spawnBoundsToggle() {
        saveEvent('spawn_bounds_toggle', {
            value: !state.spawnBoundsToggle
        })

        dispatch({type: ACTION_SPAWN_BOUNDS_TOGGLE})
    }

    return (
        <div className='root'>
            <div className='form'>
                <button className='filter-toggle-main' type='button' onClick={() => toggleSearchTarget()}>
                    {state.searchBarTarget === 'monsters' && <img className='monster-icon' src={AIBAT}></img>}
                    {state.searchBarTarget === 'items' && <img className='item-icon' src={GORE}></img>}
                </button>


                <input type="text"
                    placeholder={searchPlaceHolder()}
                    value={searchValue}
                    onChange={(e) => handSearchChange(e.target.value)}
                    onKeyPress={(e => {
                        if (e.key === 'Enter')
                            dispatch({ type: ACTION_SEARCH, value: searchValue })
                    })}
                    onFocus={() => {
                        setSearchFocus(true)
                    }}
                    onBlur={() => {
                        setSearchFocus(false)

                    }}
                />


                <div className='toolbar'>
                    {state.searchBarValue && <p className='matches'>{`${state.matches.length} Matches`}</p>}

                    <div className='toolbar-items'>
                        {/* <img className='worlds' src='https://www.pngplay.com/wp-content/uploads/8/World-Earth-Background-PNG-Image.png'></img>
                        &nbsp; */}
                        {state.activeDungeon === 0 && <img onClick={spawnBoundsToggle} className={`spawn-bounds-toggle ${state.spawnBoundsToggle ? 'active' : null}`} title='Active the spawn boundary for all mobs' src='/images/SpawnBoundsToggle.svg'></img>}
                    </div>


                </div>

            </div>
            {state.searchBarValue && <button className='clear-button' onClick={() => {
                saveEvent('search_clear_button')
                setSearchValue('')
            }}> X </button>}

        </div>
    )
}

