import { LatLngTuple } from "leaflet";


export function dekaneSpawns(id: number): LatLngTuple[] {


    switch (id) {
        //Giant Chimeradon
        case 779:
            return [[-1806.5, 5602.5]]

        //Green Trillipy
        case 1273:
            return [[-1718.5, 6162]]

        //Hazardous Blood Trillipy
        case 1731:
            return [[-1310.5, 6325.5]]

        //Hazardous Violet Trillipy
        case 2500:
            return [[-1455, 6159]]

        //Keakoon Worker Leader
        case 3017:
            return [[-1487.5, 5204.5], [-1458.5, 5307]]

        //Violet Trillipy
        case 3125:
            return [[-1537, 6099]]

        //Elite Keakoon Guard
        case 3759:
            return [[-1537, 5372.5], [-1661, 5396.5]]

        //Blue Roach
        case 4106:
            return [[-1670, 5976]]

        //Blue Roach Larva
        case 1247:
            return [[-1636.5, 5955.5]]

        //Blue Roach Queen
        case 5586:
            return [[-1711.5, 5998]]

        //Pink Roach
        case 593:
            return [[-1730, 5734.5]]

        //Pink Roach Larva
        case 1374:
            return [[-1695, 5706]]

        //Pink Roach Queen
        case 9601:
            return [[-1761.5, 5770]]

        //Hazardous Green Trillipy
        case 4545:
            return [[-1640, 6235]]

        //Elite Keakoon Worker
        case 5143:
            return [[-1449, 5370.5], [-1742, 5350]]

        //Blood Trillipy
        case 5669:
            return [[-1397, 6328]]

        //Elite Keakoon Guard Leader
        case 5760:
            return [[-1746, 5407]]

        //Mutant Keakoon Guard Leader
        case 5843:
            return [[-1356, 6376]]

        //Mutant Keakoon Worker Leader
        case 7682:
            return [[-1363, 6346.5]]

        //Keakoon Guard
        case 7824:
            return [[-1531.5, 5162.5], [-1496, 5339]]

        //Keakoon Guard Leader
        case 8363:
            return [[-1534.5, 5305], [-1660.5, 5323]]

        //Meteonyker
        case 8745:
            return [[-1818, 5562]]

        //Mutant Keakoon Worker
        case 8929:
            return [[-1385.5, 6288]]

        //Elite Keakoon Worker Leader
        case 9000:
            return [[-1701, 5368.5]]

        //Chimeradon
        case 9286:
            return [[-1812, 5640]]

        //Mutant Keakoon Guard
        case 9333:
            return [[-1361, 6319]]

        //Keakoon Worker
        case 9812:
            return [[-1449.5, 5156.5]]

        //Cillin
        case 5403 :
            return [[-1663, 6004]]


        default: return [[0, 0]]
    }

}