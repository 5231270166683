import { CSSProperties } from "react";
import { MonsterMarkerProps } from "./Monster/MonsterMarker";


export interface MarkerImageProps {
    path: string,
    height: string,
    name: string
}

export default function MarkerImage(props: MarkerImageProps) {

    function styleRoot(): CSSProperties {
        return {
            margin: '0 0 -5px 0',
            borderColor: 'black',
            
        }
    }

    return (
        <div style={styleRoot()}>
            <img src={props.path} alt={props.name} height={props.height} width='auto' max-width='50px'></img>
        </div>
    )
}