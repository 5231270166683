import { CSSProperties } from "react"

export function ClusterName(props) {

    function styleRoot(): CSSProperties {
        let style =  {
            width: 'auto',
            background: '#FF8C61',
            textAlign: 'center',
            borderRadius: '5px',
            color: '#363636',
            opacity: '0.9',
            margin: '0 auto 0 auto',
            padding: '0 2px 0 2px',
            border: 'solid 1px #363636bb',
            filter: 'drop-shadow(1px 1px 0 #363636bb) drop-shadow(-1px -1px 0 #363636bb)',
            // @ts-ignore
            '-webkit-filter': 'drop-shadow(1px 1px 0 #363636bb) drop-shadow(-1px -1px 0 #363636bb)',
        
            
            fontWeight: 'bold',
            whiteSpace: 'nowrap'
        }

        // if(props.name.trim().split(' ').length > 1) {
        //     style['fontSize'] = '12px'
        // }

        //@ts-ignore
        return style
    }

    if (props.name.toLowerCase().includes('troupe')) {
        return (null)
    }

    return (<div style={styleRoot()}>
              {props.name}
            </div>
        
    )


}