import { CSSProperties } from "react"
import { ELEMENTS } from "../../../Elements"



function styleRoot(): CSSProperties {
    return {
        position: 'relative',
        background:'#b3b3b3',
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        overflow: 'hidden',
        left: '50%',
        transform: 'translateX(-50%)',
    }
}


function styleImage(): CSSProperties {
    return {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateY(-50%) translateX(-50%)',
        height: 'auto',
        maxHeight: '25px',
        maxWidth: '25px'
    }
}



export function ElementBubble(props: any) {

    return (
        <div style={styleRoot()}>
            <img style={styleImage()} src={ELEMENTS[props.element]} title={props.element}/>
        </div>    
    )
}