import { CSSProperties } from "react"


export default function MonsterRank(props) {
    function styleRoot(): CSSProperties {
        return {
            width: '50px',
            background: '#FF8C61',
            textAlign: 'center',
            borderRadius: '5px',
            color: '#363636',
            opacity: '0.9',
            margin: '0 auto 0 auto',
            border: 'solid 1px #363636bb',
            filter: 'drop-shadow(1px 1px 0 #363636bb) drop-shadow(-1px -1px 0 #363636bb)',
            //@ts-ignore
            '-webkit-filter': 'drop-shadow(1px 1px 0 #363636bb) drop-shadow(-1px -1px 0 #363636bb)',

        }
    }
    return (
        <div style={styleRoot()}>
            <b>{props.rank.charAt(0).toUpperCase() + props.rank.slice(1)}</b>
        </div>
    )
}