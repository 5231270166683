import { CSSProperties } from "react"



function styleRoot(): CSSProperties {
    return {
        position: 'relative',
        background:'#b3b3b3',
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        overflow: 'hidden',
        left: '50%',
        transform: 'translateX(-50%)'
    }
}


function styleText(): CSSProperties {
    return {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateY(-50%) translateX(-50%)',
        margin: 0,
        whiteSpace: 'nowrap',
        color: 'white',
        fontWeight: 'bolder',
        fontSize: '13px'
    }
}



export function LevelBubble(props: any) {

    return (
        <div style={styleRoot()}>
            <p style={styleText()}>{`Lv. ${props.level}`}</p>
        </div>    
    )
}