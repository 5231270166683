import L, { DivIcon, DivIconOptions, Map as LeafletMap, PathOptions } from "leaflet";
import { CSSProperties, useContext, useState } from "react";
import ReactDOMServer from 'react-dom/server';
import { Marker, Popup, Rectangle, useMap } from "react-leaflet";
import { saveEvent } from "../../../analytics/Tracker";
import { nocontext } from "../../../App";
import { DropsEntity, Monster, SpawnsEntity } from "../../../models/Monster";
import { Data, DEKANE, getWorld, KEBARAS, MADRIGAL, Store } from "../../../Store";
import { ELEMENTS } from "../../Elements";
import { centerPoint, makeRectBounds } from "../../Map/Map";
import MarkerImage from "../MarkerImage";
import { ElementBubble } from "./MonsterInfo/ElementBubble";
import { MonsterInfo } from "./MonsterInfo/MonsterInfo";
import { MonsterLevel } from "./MonsterLevel";
import './MonsterMarker.css';
import MonsterRank from "./MonsterRank";

export function CreateMonsterMarkerProps(monster: Monster, spawn?: SpawnsEntity, x?, y?): MonsterMarkerProps {
    return {
        id: monster.id,
        world: spawn ? spawn.world : undefined,
        name: monster.name.en,
        level: monster.level,
        element: monster.element,
        rank: monster.rank,
        booty: monster.booty,
        hp: monster.hp,
        mp: monster.mp,
        exp: monster.experience,
        image: monster.image,
        loot: monster.drops!,
        spawn: spawn!,
        icon: monster.icon,
        x: x,
        y: y,
    }
}

function styleRoot(): CSSProperties {
    return {
        display: 'block',
        position: 'absolute',
        width: 'max-content',
        maxWidth: '100px',
        minWidth: '50px',
        textAlign: 'center',
        zIndex: 1000
    }
}

function styleElement(): CSSProperties {
    return {
        position: 'absolute',
        bottom: '-10%',
        left: '-10%',
        height: '15px',
        filter: 'drop-shadow(2px 2px 0 #363636) drop-shadow(-1px -1px 0 #363636)',
        //@ts-ignore
        '-webkit-filter': 'drop-shadow(2px 2px 0 #363636) drop-shadow(-1px -1px 0 #363636)',
    }
}



export interface MonsterMarkerProps {
    id: number,
    world?: number,
    name: string,
    level: number,
    element: string,
    rank: string,
    booty: number,
    hp: number,
    mp: number,
    exp: number,
    image: string,
    loot: Array<DropsEntity>,
    spawn: SpawnsEntity,
    icon: string,
    x: number,
    y: number
}

let timeoutId;
export function MonsterMarker(props: MonsterMarkerProps) {
    const [isHovering, setHovering] = useState<boolean>(false)
    const [infoVisible, setInfoVisible] = useState(false)
    const map: LeafletMap = useMap()

    const store: any = useContext(Store)
    const state: Data = store.state

    const handleMouseEnter = () => {

        timeoutId = setTimeout(() => {
            setHovering(true)
        }, 300)
    }

    const handleMouseLeave = () => {
        clearTimeout(timeoutId)
        setHovering(false)

    }


    function displayBoundary() {
        let color = props.rank === "giant" ? 'red' : '#F0F3F5'
        return <Rectangle
            bounds={makeRectBounds(props.spawn, props.world === 6063 ? MADRIGAL : KEBARAS)}
            pathOptions={{ color: '#F0F3F5', lineJoin: 'round', weight: 2, fillOpacity: 0.5 } as PathOptions}>

        </Rectangle>
    }

    function isMinimarker(id) {
        switch (id) {
            case 3359: 
            case 4571: 
                return true;

            default: return false;
        }

    }

    function displayMarker() {

        let divIcon

        if (isMinimarker(props.id)) {
            divIcon = <div style={styleRoot()} >
            <MarkerImage path={props.image} height={'30px'} name={props.name}></MarkerImage>
            {/* {map.getZoom() > 0 && props.element !== 'none' && <img style={styleElement()} src={ELEMENTS[props.element]} title={props.element}></img>} */}

        </div>
        }else {
            divIcon = <div style={styleRoot()} >
            <MonsterLevel level={props.level}></MonsterLevel>
            <MarkerImage path={props.image} height={'35px'} name={props.name}></MarkerImage>
            <MonsterRank rank={props.rank}></MonsterRank>
            {map.getZoom() > 0 && props.element !== 'none' && <img style={styleElement()} src={ELEMENTS[props.element]} title={props.element}></img>}

        </div>
        }

        

        let icon: DivIcon = L.divIcon({
            className: 'monster-marker',
            iconAnchor: [25, 35],
            popupAnchor: [0, 210],
            html: ReactDOMServer.renderToString(divIcon)
        } as DivIconOptions)



        return <Marker
            // position={centerPoint(props.spawn, getWorld(props.world))}
            //@ts-ignore
            position={[props.x, props.y]}
            icon={icon}
            // draggable
            eventHandlers={{
                'click': () => {
                    saveEvent('mob_click', {
                        mob_id: props.id,
                        mob_name: props.name
                    })

                    handleMouseLeave()
                },
                'mouseover': () => {
                    handleMouseEnter()
                },
                'mouseout': () => {
                    handleMouseLeave()
                },
                'dragend': (e) => {
                    console.log(e.target.getLatLng().toString())
                }


            }}
        >

            {displayInfo()}

        </ Marker>
    }

    function displayInfo() {
        return (
            <Popup>
                <MonsterInfo {...props}></MonsterInfo>
            </Popup>
        )
    }

    return (
        <div onContextMenu={(e) => nocontext(e)}>
            {(props.world === MADRIGAL.id || props.world === KEBARAS.id) &&
                (isHovering || state.spawnBoundsToggle) && displayBoundary()}
            {displayMarker()}
        </div>
    )
}


