import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import './App.css';
import { Map } from './components/Map/Map'
import SearchBar from './components/Search/SearchBar';
import { Item } from './models/Item';
import { Monster } from './models/Monster';
import { ACTION_ADD_MADRIGAL_DATA, ACTION_ADD_MONSTERS, ACTION_ADD_SPAWN_ITEMS, Data, Store } from './Store';
import { saveEvent, setAnalytics, updatedConsent, userIsEU } from './analytics/Tracker';
import moment from 'moment-timezone';

export function nocontext(e) {

  var clickedTag = (e == null) ? e.srcElement.tagName : e.target.tagName;
  if (clickedTag == "IMG") {
    e.stopPropagation();
    e.preventDefault();
    return false;
  }
}

let loadStart;
let monsterLoad;
let spawnItemLoad;
const COOKIE_CONSENT = 'madrigal-maps-cookie-policy'
function App() {


  const store: any = useContext(Store)
  const { dispatch } = store
  const state: Data = store.state

  const [loadingItems, setLoadingItems] = useState(true)
  const [loadingMonsters, setLoadingMonsters] = useState(true)
  const [showCookieConsent, setShowCookieConsent] = useState(false)

  document.oncontextmenu = nocontext

  useEffect(() => {

    axios.get('/env').then(r => {
      if (r.data === 'production') {
        let consent = localStorage.getItem(COOKIE_CONSENT)

        if (userIsEU(moment.tz.guess())) {

          if (consent === 'accept') {
            setAnalytics(true)
          } else if (consent === 'decline') {
            setAnalytics(false)
          } else {
            setShowCookieConsent(true)
            setAnalytics(false)
          }

        } else {
          setAnalytics(true)
          // if(!consent) {
          //   setShowCookieConsent(true)
          // }
        }
      }
    })

  }, [])


  useEffect(() => {
    loadStart = performance.now()

    // axios.get('/monsters?ids=2087')
    axios.get('/monsters')
      .then(async r => {
        monsterLoad = performance.now() - loadStart
        setLoadingMonsters(false)
        dispatch({ type: ACTION_ADD_MONSTERS, value: r.data.monsters })
      })

    axios.get('/items?spawns=true')
      .then(async r => {
        spawnItemLoad = performance.now() - loadStart
        setLoadingItems(false)
        dispatch({ type: ACTION_ADD_SPAWN_ITEMS, value: r.data.items })
      })


      axios.get('/world/madrigal')
      .then((r) => {
        dispatch({type: ACTION_ADD_MADRIGAL_DATA, value: r.data})
      })


  }, [])

  useEffect(() => {

    if (!loadingMonsters && !loadingItems) {
      saveEvent('initial_data_load_complete', {
        totalTime: performance.now() - loadStart,
        monsterTime: monsterLoad,
        spawnItemsTime: spawnItemLoad
      })

    }

  }, [loadingItems, loadingMonsters])


  function monstersToDisplay(): Monster[] {

    if (loadingMonsters || loadingItems)
      return []

    if (state.searchBarValue) {
      let mons = state.monsters.filter(m => state.matches.includes(m.id))
      return mons
    } else {
      return state.monsters!
    }
  }

  function spawningItems(): Item[] {
    if (loadingMonsters || loadingItems)
      return []


    if (state.searchBarValue) {
      let sItems = state.spawningItems.filter(si => state.matches.includes(si.id))
      return sItems
    } else {
      return state.spawningItems!
    }
  }

  function acceptCookie() {
    localStorage.setItem(COOKIE_CONSENT, 'accept')
    updatedConsent()

    setShowCookieConsent(false)

  }

  function declineCookie() {
    localStorage.setItem(COOKIE_CONSENT, 'decline')
    setShowCookieConsent(false)
  }

  function acknowledgeCookies() {
    localStorage.setItem(COOKIE_CONSENT, 'accept')
    setShowCookieConsent(false)
  }

  return (
    <div>
      {(loadingItems || loadingMonsters) && <div className='loading'>
        <p className='loading-message'>Grabbing data...</p>
        <div className='emoji-loading-anim'>
          <img src='https://cdn.discordapp.com/attachments/881328720164577322/881329892220862474/853581599500271616.png'></img>
          <img src='https://cdn.discordapp.com/attachments/881328720164577322/881329926916149278/854363224518819840.png'></img>
          <img src='https://cdn.discordapp.com/attachments/881328720164577322/881329949712191508/854101123040149504.png'></img>
        </div>
      </div>}
      {(!loadingItems && !loadingMonsters) && <SearchBar></SearchBar>}
      {state.monsters && <Map monsters={monstersToDisplay()} itemSpawns={spawningItems()} />}
      {
        showCookieConsent && <div className={'cookie-consent'}>
          This site uses <a href={`${window.location}cookie-policy`} target="_blank">cookies</a> via Google Analytics to help guide development.
          {userIsEU(moment.tz.guess()) ? <div className='consent-buttons'>
            <button onClick={acceptCookie} className='cookie-consent-button'>Accept</button>
            <button onClick={declineCookie} className='cookie-optout-button'>Decline</button>
          </div> : <div className='consent-buttons'>
            <button onClick={acknowledgeCookies} className='cookie-consent-button'>Acknowledge</button>
          </div>}

        </div>
      }
    </div>
  );
}

export default App;
