import { createContext, useReducer } from 'react';
import { JOBS, Job } from './Jobs';
import { Item } from './models/Item';
import { Monster } from './models/Monster';
import { World } from './models/World';

export const ACTION_ADD_MONSTERS = 'ADD_MONSTERS'
export const ACTION_ADD_SPAWN_ITEMS = 'ADD_SPAWN_ITEMS'
export const ACTION_SEARCH = 'SEARCH'
export const ACTION_TOGGLE_DISPLAY_LOOT = 'TOGGLE_DISPLAY_LOOT'
export const ACTION_UPDATE_CATEGORY_FILTER = 'UPDATE_CATEGORY_FILTER'
export const ACTION_UPDATE_JOB_FILTER = 'UPDATE_JOB_FILTER'
export const ACTION_SET_SEARCHBAR_TARGET = 'SET_SEARCHBAR_TARGET'
export const ACTION_SPAWN_BOUNDS_TOGGLE = 'SPAWN_BOUNDS_TOGGLE'
export const ACTION_ADD_MADRIGAL_DATA = 'ADD_MADRIGAL_DATA'
export const ACTION_DUNGEON_CLICKED = 'DUNGEON_CLICKED'

export interface world {
  id: number,
  tileName: string,
  tileSize: number,
  width: number,
  height: number
}

export const MADRIGAL: world = { id: 6063, tileName: 'wdmadrigal', tileSize: 512, width: 10752, height: 5632 };
export const KEBARAS: world = { id: 4839, tileName: 'wdkebaras', tileSize: 512, width: 2560, height: 2560 };
export const IBLIS: world = { id: 615, tileName: 'dusatemple', tileSize: 512, width: 768, height: 768 };
export const DEKANE: world = { id: 2927, tileName: 'dudadk', tileSize: 512, width: 2560, height: 1536 };
export const MARS: world = { id: 4015, tileName: 'duflmas', tileSize: 512, width: 600, height: 600 };


export function getWorld(id: number): world {
  switch (id) {
    case KEBARAS.id: return KEBARAS;
    case IBLIS.id: return MADRIGAL;
    case DEKANE.id: return MADRIGAL;
    default: return MADRIGAL;
  }
}


export const WEAPON = 'weapon'
export const ARMOR = 'armor'
export const JEWLERY = 'jewelry'
export const MISC = 'misc'


export interface Data {
  monsters: Monster[],
  spawningItems: Item[],
  matches: number[],
  searchBarValue?: string,
  searchBarTarget: string,
  lootDisplayed: boolean
  categoryFilter: string,
  firstJobFilter: Job | null,
  jobFilters: Array<number> | null,
  spawnBoundsToggle: boolean,
  madrigal?: World
  activeDungeon: number
}

const data: Data = {
  monsters: [],
  spawningItems: [],
  matches: [],
  lootDisplayed: false,
  categoryFilter: '',
  firstJobFilter: null,
  jobFilters: null,
  searchBarTarget: 'monsters',
  spawnBoundsToggle: false,
  activeDungeon: 0
};
const Store = createContext(data);
const { Provider } = Store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state: Data, action) => {
    switch (action.type) {
      case ACTION_ADD_MONSTERS:

        return { ...state, monsters: action.value }
      case ACTION_ADD_SPAWN_ITEMS:

        return { ...state, spawningItems: action.value }

      case ACTION_ADD_MADRIGAL_DATA:
        return { ...state, madrigal: action.value }

      case ACTION_DUNGEON_CLICKED:

        if (state.activeDungeon === action.value)
          return { ...state, activeDungeon: 0 }
        else return { ...state, activeDungeon: action.value }


      case ACTION_SEARCH:

        if (!action.value) {
          return { ...state, matches: [], searchBarValue: null }
        }

        let matches: number[] = []

        let query: string = (action.value as string).toLowerCase()


        if (state.searchBarTarget === 'monsters') {
          let monsters = state.monsters

          if(state.activeDungeon !== 0) {
            monsters = state.monsters.filter(m => m.area === 'dungeon')
          }



          matches = monsters.filter(m =>
            m.name.en.toLowerCase().includes(query) ||
            m.rank.toLowerCase().startsWith(query)
          ).map(m => m.id)
        }

        if (state.searchBarTarget === 'items') {
          matches = state.monsters.filter(m => {
            if (!m.drops)
              return false

            for (let i = 0; i < m.drops?.length; i++) {
              if (m.drops[i].name.en.toLowerCase().includes(query))
                return true
            }
          }
          ).map(m => m.id)

          matches = matches.concat(state.spawningItems.filter(si => si.name.en.toLowerCase()
            .includes((action.value as string).toLowerCase())).map(si => si.id))
        }

        return { ...state, matches: matches, searchBarValue: action.value }

      case ACTION_TOGGLE_DISPLAY_LOOT:
        return { ...state, lootDisplayed: !state.lootDisplayed }

      case ACTION_UPDATE_CATEGORY_FILTER:

        if (state.categoryFilter === action.value)
          return { ...state, categoryFilter: '' }
        else
          return { ...state, categoryFilter: action.value }

      case ACTION_UPDATE_JOB_FILTER:

        let job: Job = action.value

        let currentFilters = state.jobFilters

        if (!currentFilters) {
          //no filter, first job clicked
          return { ...state, firstJobFilter: job, jobFilters: job.filters }
        } else {
          //already filtered, first job clicked
          if (job.branches) {
            //clicked enabled first job, disable filters
            if (currentFilters.toString() === job.filters.toString()) {
              return { ...state, firstJobFilter: null, jobFilters: null }
            }

            //clicked different first job
            return { ...state, firstJobFilter: job, jobFilters: job.filters }
          } else {

            if (currentFilters.length === 4) {
              //clicked new 2nd job
              return { ...state, jobFilters: job.filters }
            } else {
              //2nd job already filtered, clicked 2nd job again

              if (currentFilters.toString() === job.filters.toString()) {
                //clicked enabled 2nd job, disable it without disabling first job filter
                return { ...state, jobFilters: JOBS.filter(job => job.id === currentFilters![1])[0].filters }
              } else {
                return { ...state, jobFilters: job.filters }
              }
            }
          }
        }

      case ACTION_SET_SEARCHBAR_TARGET:
        return { ...state, searchBarTarget: action.value }

      case ACTION_SPAWN_BOUNDS_TOGGLE:
        return { ...state, spawnBoundsToggle: state.spawnBoundsToggle ? false : true }


      default: {
        console.log(action)
        throw new Error();
      }

    };
  }, data);

  //@ts-ignore
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { Store, StateProvider }