import L, { DivIcon, DivIconOptions } from "leaflet";
import { CSSProperties, useContext, useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import { Marker, useMap } from "react-leaflet";
import { saveEvent } from "../../analytics/Tracker";
import { Monster } from "../../models/Monster";
import { Place } from "../../models/World";
import { ACTION_DUNGEON_CLICKED, Data, MADRIGAL, Store } from "../../Store";
import { DungeonName } from "./DungeonName";


export interface DungeonMarkerProps {
    dungeon: Place
}

export function DungeonMarker(props: DungeonMarkerProps) {
    const store: any = useContext(Store)
    const { dispatch } = store
    const state: Data = store.state

    const map = useMap()

    
    useEffect(() => {
        // console.log(props.dungeon)

        state.monsters.forEach(m => {
            if(m.spawns && m.spawns.length > 0) {
                m.spawns.forEach(spawn => {
                    // if (spawn.world === props.dungeon.id)
                    //     console.log(m.name.en)
                })
            }
        })

    
    }, [state.monsters]);

    function styleRoot(): CSSProperties {
        return {
            width: 'max-content',
            textAlign: 'center'

        }
    }

    function displayMarker() {
        //get dungeon name, monster img, contents, max lvl
        let divIcon = <div style={styleRoot()}>
            <DungeonName name={props.dungeon.name.en}/>
            <img height='30px' src='/images/dungeon.svg'></img>
        </div>

        let icon: DivIcon = L.divIcon({
            className: 'monster-marker',
            iconAnchor: [25, 35],
            popupAnchor: [0, 210],
            html: ReactDOMServer.renderToString(divIcon)
        } as DivIconOptions)

        return <Marker
            // position={[props.dungeon.location.z * -1, props.dungeon.location.x]}
            position={[props.dungeon.location.y, props.dungeon.location.x]}
            icon={icon}
            // draggable
            eventHandlers={{
                'click': () => {
                    saveEvent('dungeon_click', {
                        dungeon_id: props.dungeon.id,
                        dungeon_name: props.dungeon.name.en
                    })


                    dispatch({type: ACTION_DUNGEON_CLICKED, value: props.dungeon.id})


                    if (map.getZoom() < 1 && state.activeDungeon !== props.dungeon.id) {
                        map.flyTo([props.dungeon.location.y, props.dungeon.location.x], 1)
                    }



                },

                'dragend': (e) => {
                    console.log(e.target.getLatLng().toString())
                }


            }}
        >

        </ Marker>
    }

    return (
        <div>
            {displayMarker()}
        </div>
    )
}