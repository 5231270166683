import { CSSProperties, useContext } from "react"
import { saveEvent } from "../../../../../analytics/Tracker"
import { Job, JOBS } from "../../../../../Jobs"
import { ACTION_UPDATE_JOB_FILTER, Data, Store } from "../../../../../Store"



export function JobFilter(props) {
    const store: any = useContext(Store)
    const { dispatch } = store
    const state: Data = store.state

    function styleRoot(): CSSProperties {
        return {
            display: 'flex',
            justifyContent: 'space-around',
            padding: '5px 0 0 0',
            boxShadow: '0 -3px 5px #04040452',
        }
    }

    function styleImage(jobId: number): CSSProperties {
        let style: CSSProperties = {
            cursor: 'pointer',
            width: '30px',
            height: '30px',
            margin: '2px 2px 2px 2px'

        }


        if ((state.firstJobFilter && state.firstJobFilter.id === jobId)  || state.jobFilters && state.jobFilters.length === 3 && state.jobFilters.includes(jobId)  ) {
            style.border = 'solid 2px #b3b3b3'
            style.borderRadius = '5px'
            style.margin = '0'
        }

        return style
    }

    function handleFilterChange(job: Job) {
        saveEvent('job_filter', {
            job: job.name
        })
        
        dispatch({ type: ACTION_UPDATE_JOB_FILTER, value: job })
    }


    function renderFilters() {
        return <div className='first-job-filters'>
            {JOBS.map((firstJob: Job) => {
                return <img key={firstJob.id} style={styleImage(firstJob.id)} onClick={() => handleFilterChange(firstJob)}
                    src={firstJob.icon}
                    title={`Filter ${firstJob.name}`}
                />
            })}
        </div>
    }

    return (
        <div style={styleRoot()}>
            {renderFilters()}
            {state.firstJobFilter && <div className='second-job-filters'> 
                {state.firstJobFilter.branches?.map(secondJob => {
                    return <img key={secondJob.id} style={styleImage(secondJob.id)} onClick={() => handleFilterChange(secondJob)}
                        src={secondJob.icon}
                        title={`Filter ${secondJob.name}`}
                    />
                })}

            </div>}
        </div>
    )
}