import L, { LatLngTuple, Map as LeafletMap } from "leaflet";
import { Monster } from "../../../models/Monster";
import { getWorld, KEBARAS, MADRIGAL } from "../../../Store";
import { centerPoint } from "../../Map/Map";
import { MonsterMarkerProps, CreateMonsterMarkerProps } from "../Monster/MonsterMarker";


export interface MonsterPoint {
    type: string,
    properties: {
        id: string,
        cluster: boolean,
        alreadyClustered: boolean,
        monster: MonsterMarkerProps
    },
    geometry: {
        type: string,
        coordinates: Array<number>
    }
}

export function rotatePoint(x: number, y: number, cx: number, cy: number, degrees: number): LatLngTuple {
    let cos = Math.cos(degrees)
    let sin = Math.sin(degrees)

    let nx = ((x-cx)*cos - (y-cy)*sin) + cx; 
    let ny =  ((x-cx)*sin + (y-cy)*cos) + cy;

    return [nx, ny]
}

export function points(monsters: Monster[], map: LeafletMap): Array<MonsterPoint> {
    let spawnPoints: any[] = []

    monsters.forEach((m, i) => {
        if (m.spawns && m.spawns.length > 0) {
            m.spawns.forEach((spawn, j) => {

                if ((spawn.world === MADRIGAL.id || spawn.world === KEBARAS.id) && map.getBounds().contains(L.latLng(spawn.y!, spawn.x!))) {
                    let c = centerPoint(spawn, getWorld(spawn.world))

                    spawnPoints.push(
                        {
                            type: 'Feature',
                            properties: { id: `${i}-${j}`, cluster: false, monster: CreateMonsterMarkerProps(m, spawn, c[0], c[1]) },
                            geometry: {
                                type: 'Point',
                                coordinates: [spawn.y, spawn.x]
                            }
                        }
                    )
                }
            })
        }
    })
    return spawnPoints
}

export function groupByName(monsterGroup: MonsterPoint[]): Map<string, MonsterPoint[]> {
    let groups: Map<string, MonsterPoint[]> = new Map()

    monsterGroup.forEach((mp: MonsterPoint) => {

        let nameLastPiece = mp.properties.monster.name.split(' ').pop()

        if (!groups.has(nameLastPiece!)) {
            groups.set(nameLastPiece!, [])
        }

        groups.get(nameLastPiece!)?.push(mp)
    })

    return groups
}

export function groupByBooty(monsterGroup: MonsterPoint[]): Map<string, MonsterPoint[]> {
    let groups: Map<string, MonsterPoint[]> = new Map()

    monsterGroup.forEach((mp: MonsterPoint, i) => {
        let propertyToGroupWith;

        let nameLastPiece = mp.properties.monster.name.split(' ').pop()

        if(nameLastPiece?.toLowerCase() === 'troupe') {
            propertyToGroupWith = i.toString()
        } else if (mp.properties.monster.name.toLowerCase().includes('red bang')) {
            propertyToGroupWith = 'Red bang'
        } else {
            propertyToGroupWith = mp.properties.monster.booty || nameLastPiece
        }


        if (!groups.has(propertyToGroupWith)) {
            groups.set(propertyToGroupWith, [])
        }

        groups.get(propertyToGroupWith)?.push(mp)
    })

    return groups
}



function calculateDistance(point1: MonsterPoint, point2: MonsterPoint): number {

    let x1 = point1.geometry.coordinates[0]
    let y1 = point1.geometry.coordinates[1]

    let x2 = point2.geometry.coordinates[0]
    let y2 = point2.geometry.coordinates[1]

    let x = x1 - x2
    let y = y1 - y2

    let distance = Math.sqrt((x * x) + (y * y))
    return distance;
}



function inClusteringDistance(distance: number, zoom: number): boolean {
    if (zoom < -2 && distance < 725) {
        return true
    } else if (zoom === -2 && distance < 725) {
        return true
    } else if (zoom === -1 && distance < 725) {
        return true
    } else if (zoom > -1 && distance === 0) { 
        return true
    }

    return false
}



export function clusterByDistance(monsters: Monster[], map: LeafletMap): Map<string, MonsterPoint[]> {
    let spawnPoints = points(monsters, map)
    //@ts-ignore
    let pointMap = new Map<string, MonsterPoint[]>()
 

    groupByBooty(spawnPoints).forEach((group: MonsterPoint[], name: string) => {
        for (let i = 0; i < group.length; i++) {
            let point1 = group[i]
            let alreadyClustered = point1.properties.alreadyClustered

            if (alreadyClustered) {
                continue
            } else {
                let clusterId = point1.properties.id
                pointMap.set(clusterId, [])

                for (let j = 0; j < group.length; j++) {
                    let point2 = group[j]

                    let distance = calculateDistance(point1, point2)

                    if (inClusteringDistance(distance, map.getZoom())) {
                        pointMap.get(clusterId)?.push(point2)
                        group[j].properties['alreadyClustered'] = true
                    }
                }

            }
        }
    })

    return pointMap
}