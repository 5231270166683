import { CSSProperties } from "react"
import {cultured} from '../../colors'
export function DungeonName(props) {

    function styleRoot(): CSSProperties {
        let style =  {
            width: 'auto',
            background: '#3A7D44',
            textAlign: 'center',
            borderRadius: '5px',
            color: cultured,
            opacity: '0.9',
            margin: '0 auto 0 auto',
            padding: '0 2px 0 2px',
            border: 'solid 1px #363636bb',
            filter: 'drop-shadow(1px 1px 0 #363636bb) drop-shadow(-1px -1px 0 #363636bb)',
            // @ts-ignore
            '-webkit-filter': 'drop-shadow(1px 1px 0 #363636bb) drop-shadow(-1px -1px 0 #363636bb)',
        
            fontWeight: 'bold',
            whiteSpace: 'nowrap'
        }

        //@ts-ignore
        return style
    }

    return (<div style={styleRoot()}>
              {props.name}
            </div>
        
    )


}