import { LatLngTuple } from "leaflet";


export function ibilisSpawns(id: number): LatLngTuple[] {
    switch (id) {
        //Iblis Quaker
        case 2671: return [[-3004.5, 8475]]

        //Iblis Wrecker
        case 2984: return [[-3016.5, 8519.5], [-3303.5, 8776]]

        //Iblis Boxter
        case 3359: return [[-3345, 8419], [-3396.5, 8461.5], [-3386, 8599], [-3328.5, 8572.5], [-3328.5, 8633.5], [-3328.5, 8633.5], [-3345.5, 8716.5], [-3346, 8774.5],
        [-3247.5, 8486.5], [-3242.5, 8625.5], [-111, 222], [-3235, 8847.5], [-3187.5, 8691.5], [-3209.5, 8694.5], [-3192.5, 8855],
        [-3076.5, 8600.5], [-3063.5, 8570.5], [-3021, 8453.5], [-3000.5, 8734.5], [-2964, 8824],
        [-2838, 8609], [-2839, 8654.5], [-2867, 8613], [-2866.5, 8657]]

        //Iblis Guardian
        case 3566: return [[-3363.5, 8415]]

        //Iblis Crasher
        case 3591: return [[-3352, 8446.5], [-3289.5, 8483.5], [-3196.5, 8467.5]]

        //Iblis Destroyer
        case 4455: return [[-3228, 8809], [-3156, 8756], [-3106.5, 8869]]

        //Iblis Red Otem
        case 4571: return [[-3191.5, 8491.5], [-3190, 8590.5], [-3187, 8665.5], [-3209, 8673], [-3186.5, 8714], [-3211.5, 8712.5], 
        [-3259, 8591], [-3277.5, 8602], [-3295, 8599], [-3310.5, 8614.5], [-3311.5, 8587], 
        [-3350.5, 8567], [-3349.5, 8640.5],[-3385.5, 8616]]

        //Iblis Mushellizer
        case 4770: return [[-2929, 8759.5]]

        //Iblis Thief
        case 6141: return [[-2953.5, 8680.5]]

        //Iblis Puppet
        case 8502: return [[-3039, 8797], [-2960.5, 8799]]

        //Iblis Poisoner
        case 8659: return [[-3166.5, 8518.5], [-3066.5, 8488.5], [-3145, 8438.5]]

        //Rubbed Copy of the Iblis Revelation
        case 1942 : return [[-3399, 8449]]

        default: return []
    }
}