import { LatLngTuple } from "leaflet";


export function marsSpawns(id: number): LatLngTuple[] {
    switch(id) {
        //Serus Uriel
        case 421: return [[-1200, 7956]]

        //Mutant Boss Bang
        case 2484: return [[-992, 7789], [-1093, 7765.5]]

        //Mutant Giant Bang King
        case 2979: return [[-980.5, 7768], [-1083.5, 7866.5]]

        //Mutant Fefern
        case 3415: return [[-1065.5, 7630], [-1072.5, 7683.5], [-1014, 7721], [-1125.5, 7608], [-1223.5, 7661.5]]

        //Mutant Giant 2nd Class Fefern
        case 3716: return [[-966, 7715], [-1007.5, 7823.5]]

        //Vice Veduque
        case 3882: return [[-1154, 7913]]

        //Mutant Nyangnyang
        case 4198: return [[-1129, 7684.5], [-1162.5, 7719]]

        //Worm Veduque
        case 5517: return [[-1225, 7922.5]]

        //Mutant Bang
        case 5566: return [[-1114, 7809.5], [-1094.5, 7732]]

        //Mutant Nyangnyang Queen
        case 6345: return [[-1267, 7765], [-1190, 7833.5], [-1207.5, 7733]]

        //Mutant Giant Nyangnyang
        case 7328: return [[-1053, 7835.5]]

        //Mutant Fefern King
        case 9137: return [[-1013.5, 7636], [-1009, 7691], [-1057.5, 7712.5]]

        //Piece Of Key
        case 5037: return [[-1168.5, 7639.5], [-1053, 7650], [-1142.5, 7706.5], [-1080, 7775.5]]

        default: 
            return []

    }
}