import { spawn } from 'child_process';
import L, { Map as LeafletMap } from 'leaflet';
import { useContext, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { Store, Data, MADRIGAL } from '../../Store';

export interface ZoomControllerProps {
    map: LeafletMap,
}

export function ZoomController(props: ZoomControllerProps) {
    const store: any = useContext(Store)
    const { dispatch } = store
    const state: Data = store.state

    useEffect(() => {
        if (state.spawnBoundsToggle && props.map.getZoom() < 0)
            props.map.setZoom(1)

    }, [state.spawnBoundsToggle]);


    useEffect(() => {
        if (state.matches.length === 0) {

        } else {
            setTimeout(() => {

                let monsters = state.monsters.filter(m => state.matches.includes(m.id))
                let items = state.spawningItems.filter(s => state.matches.includes(s.id))

                let miBounds: any = []

                monsters.forEach(m => {
                    if (m.spawns && m.spawns.length > 0) {
                        m.spawns.forEach(spawn => {
                            if (spawn.world === MADRIGAL.id)
                                miBounds.push([spawn.y, spawn.x])
                            else
                                miBounds.push([spawn.x, spawn.y])
                            
                        })
                    }
                })

                items.forEach(i => {
                    if (i.spawns && i.spawns.length > 0) {
                        i.spawns.forEach(spawn => {
                            if (spawn.world === MADRIGAL.id)
                                miBounds.push([spawn.y, spawn.x])
                            else
                                miBounds.push([spawn.x, spawn.y])
                        })
                    }
                })

                let bounds: L.LatLngBounds = new L.LatLngBounds(miBounds)
                console.log(bounds.getNorthEast())

                try {
                    props.map.fitBounds(bounds)

                } catch (e) {
                    console.log(e)
                }
            }, 100)

        }



    }, [state.matches]);


    return (null)
}